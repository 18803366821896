// eslint-disable-next-line vue/multi-word-component-names
<script setup></script>

<template>
  <div
    class="max-w-2xl p-12 rounded-md flex flex-col w-[500px] -translate-x-1/2 absolute -translate-y-1/2 top-[40%] left-1/2"
  >
    <h1 class="text-3xl font-bold text-center">Purchasing Admin</h1>

    <NuxtLink
      to="/login"
      class="min-w-[200px] text-center font-semibold text-xl mt-24 px-4 py-2 text-white bg-indigo-500 rounded no-underline"
    >
      Login
    </NuxtLink>
  </div>
</template>
